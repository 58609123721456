import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import loginImage from "../images/logo-image.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import configData from "../config.js";

function Login() {
  const { t } = useTranslation();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isRequired, setIsRequired] = useState();
  const [isValidate, setIsValidate] = useState();
  const [disableLogin, setDisableLogin] = useState(false);
  const [logo, setLogo] = useState("./Images/CerocLogo.png");
  const [token, setToken] = useState();
  const [inputType, setInputType] = useState("password");
  const [profilePic, setProfilePic] = useState("./Images/defaultpp.png");
  const navigate = useNavigate();

  const handleIcon = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const memberId = localStorage.getItem("memberId");
    if (token) {
      if (memberId) {
        axios
          .get(`${configData.VENUE_URL}Member/${memberId}/getmember`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
            },
          })
          .then((res) => {
            let headerImage =
              res.data.payload.memberAvatarURL !== ""
                ? res.data.payload.memberAvatarURL
                : profilePic;
            setProfilePic(headerImage);
            localStorage.setItem(
              "territoryNumber",
              res.data.payload.territoryNumber
            );
            localStorage.setItem("username", res.data.payload.firstName);
            localStorage.setItem("profilepic", headerImage);
            localStorage.setItem("currencyCode", res.data.payload.currencyCode);
            localStorage.setItem(
              "territoryName",
              res.data.payload.territoryName
            );
            navigate("/home");
            setDisableLogin(true);
            activityLog()
              .then(() => {})
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((err) => {
            if (err.response.data.status === 401) {
              navigate("/");
            }
          });
      }
    }
  }, []);

  const getMemberId = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${configData.MEMBER_URL}User/${email}/getmemberId`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        localStorage.setItem("memberId", res.data.result);
        let memberId = res.data.result ?? "";
        if (memberId) {
          axios
            .get(`${configData.VENUE_URL}Member/${memberId}/getmember`, {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
                "Content-Type":
                  "application/x-www-form-urlencoded;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
              },
            })
            .then((res) => {
              let headerImage =
                res.data.payload.memberAvatarURL !== ""
                  ? res.data.payload.memberAvatarURL
                  : profilePic;
              setProfilePic(headerImage);
              localStorage.setItem(
                "territoryNumber",
                res.data.payload.territoryNumber
              );
              localStorage.setItem("username", res.data.payload.firstName);
              localStorage.setItem("profilepic", headerImage);
              localStorage.setItem(
                "currencyCode",
                res.data.payload.currencyCode
              );
              localStorage.setItem(
                "territoryName",
                res.data.payload.territoryName
              );
              navigate("/home");
              setDisableLogin(true);
              activityLog()
                .then(() => {})
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.status === 401) {
                navigate("/");
              }
            });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsRequired(false);
    setIsValidate(false);
    setDisableLogin(true);

    if (email === undefined && password === undefined) {
      setIsRequired(true);
      setDisableLogin(false);
    } else {
      let credentials = {
        client_id: "BookingzAppVenueApp",
        client_secret: "486F798C-243C-4C82-81EC-E7D00E9D5874",
        grant_type: "password",
        scope:
          "bookingzapp.member.read bookingzapp.member.write bookingzapp.venue.read bookingzapp.venue.write",
        username: email.trim(),
        password: password,
      };

      let formBody = [];
      for (let property in credentials) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(credentials[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      await fetch(`${configData.AUTH_URL}connect/token`, {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((data) => {
          //handle data
          if (data.access_token) {
            setToken(data.access_token);
            localStorage.setItem("token", data.access_token);
            localStorage.setItem("offlineDataStoreWarning", "yes");
            getMemberId();
            setTimeout(() => {}, 4000);
          } else if (data.error === "invalid_request") {
            setIsValidate(true);
            setDisableLogin(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const activityLog = async () => {
    let loginMemberId = localStorage.getItem("memberId");
    let token = localStorage.getItem("token");

    const activityLogData = {
      memberID: loginMemberId,
      activityTypeName: "Login",
      activityDescription: "User Logged In",
    };
    const res = await fetch(
      `${configData.VENUE_URL}Application/addactivitylog`,
      {
        method: "post",
        body: JSON.stringify(activityLogData),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
    const data = await res.json();
    return data;
  };

  return (
    <div className="row no-gutter borderTop">
      <div className="col-md-7 d-none d-md-flex bg-image">
        <img
          src={logo}
          alt="logo"
          width="100"
          height="100"
          className="logo p-3"
        />
        <img
          src={loginImage}
          alt="logo"
          width="550"
          height="450"
          className="login-image p-5"
        />
      </div>

      <div className="col-md-5 bg-light">
        <div className="login d-flex align-items-center">
          <div className="container">
            <div className="row content-center">
              <div className="col-lg-10 col-xl-9 mx-auto">
                <h2 className="">{t("SIGN IN")}</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t("Email")}
                    />
                    <label htmlFor="floatingInput">{t("Email")}</label>
                    {isValidate && (
                      <div className="mb-2">
                        <span className="text-danger mb-2">
                          {t("Entered Email doesn't exist!")}
                        </span>
                      </div>
                    )}
                    {isRequired && (
                      <div className="mb-2">
                        <span className="text-danger">
                          {t("Email is required")}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="form-floating label-eye-wrapper">
                    <input
                      type={inputType}
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder={t("Password")}
                    />
                    {inputType === "password" ? (
                      <RiEyeCloseLine
                        className="eye-icon"
                        onClick={handleIcon}
                      />
                    ) : (
                      <RiEyeLine className="eye-icon" onClick={handleIcon} />
                    )}
                    <label htmlFor="floatingPassword">{t("Password")}</label>
                    {isValidate && (
                      <div className="mb-2">
                        <span className="text-danger mb-2">
                          {t("Entered Password is incorrect!")}
                        </span>
                      </div>
                    )}
                    {isRequired && (
                      <div className="mb-2">
                        <span className="text-danger mb-2">
                          {t("Password is required")}
                        </span>
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn background-orange form-control mt-3 text-white"
                    disabled={disableLogin}
                  >
                    {t("Sign In")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
