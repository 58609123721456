import * as React from "react";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RecordAttendance from "./RecordAttendance";
import "./../../App.css";
import AddExpense from "./AddExpense";

export default function NewEventListing({
  eventList,
  filterFn,
  handleModal,
  t,
  mode,
  toggle,
}) {
  const [order, setOrder] = React.useState();
  const [orderBy, setOrderBy] = React.useState();
  const pages = [5, 10, 15];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pages[page]);
  const [currentEventId, setCurrentEventId] = useState("");
  const [recordAttendance, setRecordAttendence] = useState(false);
  const [currentEventType] = useState();
  const [addExpense, setAddExpense] = useState(false);
  const navigate = useNavigate();
  const [, setAttendancedRecorded] = useState(false);

  const headCells = [
    {
      id: "eventID",
      label: `${t("Event Name")}`,
      disableSorting: true,
      align: "right",
    },
    {
      id: "eventStart",
      label: `${t("Date")}`,
      align: "left",
    },
    {
      id: "eventTime",
      label: `${t("Time")}`,
      align: "center",
    },
    {
      id: "venueKnownAs",
      label: `${t("Venue")}`,
      align: "center",
    },
    {
      id: "eventTypeName",
      label: `${t("Type")}`,
      align: "center",
    },
    {
      id: "totalBookings",
      label: `${t("Pre-Books")}`,
      align: "center",
    },
    {
      id: "totalAttendence",
      label: `${t("Attendences")}`,
      align: "center",
    },
    {
      id: "recordAction",
      label: `${t("Record Attendence")}`,
      disableSorting: true,
      align: "center",
    },
    {
      id: "eventStatusName",
      label: `${t("Status")}`,
      align: "center",
    },
    {
      id: "expenditureAction",
      label: `${t("Expenditure")}`,
      disableSorting: true,
      align: "center",
    },
  ];

  const formattedDate = (date) => {
    let newDate = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let mnth = newDate.getMonth();
    let day = ("0" + newDate.getDate()).slice(-2);
    return [day, monthNames[mnth], newDate.getFullYear()].join(" ");
  };

  const formattedTime = (date) => {
    let newDate = new Date(date);
    return [
      ("0" + newDate.getHours()).slice(-2),
      ("0" + newDate.getMinutes()).slice(-2),
    ].join(":");
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpenseModal = (event) => {
    setAddExpense(true);
    setCurrentEventId(event.eventID);
  };

  const checkEventIsActive = (time) => {
    let today = new Date();
    let currentTime = today.getHours() + 2 + ":" + today.getMinutes()
    let eventTime = formattedTime(time)
    if (formattedDate(time) === formattedDate(today) && currentTime >= eventTime) {
      return true;
    }
  };

  function callbackFunction(value) {
    setAttendancedRecorded(value);
  }

  return (
    <div
      style={
        toggle
          ? { marginLeft: "2rem", overflowX: "auto" }
          : { overflowX: "auto" }
      }
    >
      <Box
        sx={{
          display: "flex",
          ml: "2rem",
        }}
      >
        {recordAttendance ? (
          <RecordAttendance
            recordAttendence={recordAttendance}
            setRecordAttendence={setRecordAttendence}
            eventID={currentEventId}
            handleModal={handleModal}
            eventTypeName={currentEventType}
            t={t}
            mode={mode}
            callbackFunction={callbackFunction}
          />
        ) : null}
        <div className="mt-3">
          <table className="table table-striped bg-light mt-3 p-3 border-white rounded-4 overflow-hidden">
            <thead>
              <tr className="text-white background-orange text-truncate">
                {headCells.map((headCell) => (
                  <th
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                    align={headCell.align}
                  >
                    {headCell.disableSorting ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => handleSortRequest(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {eventList?.length > 0 ? (
              <tbody>
                {stableSort(
                  filterFn.fn(eventList),
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((event) => {
                    return (
                      <tr hover key={event.eventID}>
                        <td component="th" padding="none" align="left">
                          <Link
                            to={"/eventDetails"}
                            sx={{ color: "royalblue" }}
                            state={{
                              eventId: event.eventID,
                              eventTypeName: event.eventTypeName,
                            }}
                          >
                            {event.eventName}
                          </Link>
                        </td>
                        <td align="left">
                          {event.eventStart
                            ? formattedDate(event.eventStart)
                            : ""}
                        </td>
                        <td align="left">
                          {event.eventStart
                            ? formattedTime(event.eventStart)
                            : ""}
                        </td>
                        <td align="left">{event.venueKnownAs}</td>
                        <td align="left">{event.eventTypeName}</td>
                        <td align="center">{event.totalBookings}</td>
                        <td align="center">{event.totalAttendence}</td>
                        <td align="left">
                          {event.recordAction === "Record" &&
                          checkEventIsActive(event.eventStart) ? (
                            <span
                              style={{
                                color: "royalblue",
                                textDecoration: "underLine",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate("/searchMember", {
                                  state: { eventID: event.eventID },
                                })
                              }
                            >
                              {event.recordAction}
                            </span>
                          ) : (
                            event.recordAction
                          )}
                        </td>
                        <td align="left">{event.eventStatusName}</td>
                        <td align="left">
                          <span
                            style={{
                              color: "royalblue",
                              textDecoration: "underLine",
                              cursor: "pointer",
                            }}
                            onClick={() => handleExpenseModal(event)}
                          >
                            {event.expenditureAction}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={10} align="center">
                    No records Found
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <AddExpense
          eventID={currentEventId}
          modal={addExpense}
          setModal={setAddExpense}
          // eventExpenseTypes={eventExpenseTypes}
        ></AddExpense>
      </Box>
      <TablePagination
        rowsPerPageOptions={pages}
        component="div"
        count={eventList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
