import axios from "axios";
import React from "react";
import { useEffect } from "react";
import configData from "./../../config.js";

const StoringDataInDb = ({
  events,
  setDownloadDataInIndexedDB,
  setDataSynced,
  setOfflineMessage,
}) => {
  const token = localStorage.getItem("token");
  const DB_NAME = "MyDatabase";
  const DB_VERSION = 1;

  useEffect(() => {
    setDownloadDataInIndexedDB(false);

    let request = window.indexedDB.open(DB_NAME, DB_VERSION);
    // To create the database and the store
    request.onupgradeneeded = (event) => {
      let db = event.target.result;
      db.createObjectStore("HomeEvents", {
        keyPath: "id",
      });
      db.createObjectStore("AddMember", {
        keyPath: "id",
      });

      db.createObjectStore("ActiveUsers", {
        keyPath: "id",
      });
      db.createObjectStore("Expensetypes", {
        keyPath: "id",
      });
      db.createObjectStore("UpdateMember", {
        keyPath: "id",
      });

      events?.forEach((event) => {
        db.createObjectStore(`EntryType${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`EventInfo${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`EventBookings${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`EventAttendance${event?.eventID}`, {
          keyPath: "id",
        });
        db.createObjectStore(`RecordAttendance${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`CreateAttendance${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`UpdateAttendance${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`Expenditure${event?.eventID}`, {
          keyPath: "id",
        });
        db.createObjectStore(`AddExpenditure${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`DeleteExpenditure${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`DeleteAttendance${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`BookedCount${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        db.createObjectStore(`BookingAmount${event?.eventID}`, {
          keyPath: "id",
          autoIncrement: true,
        });
        setDataSynced(true);
        setOfflineMessage("Events data available in offline mode");
      });
    };

    request = window.indexedDB.open("MyDatabase", 1);
    request.onsuccess = (event) => {
      let db = event.target.result;
      let transaction = db.transaction("HomeEvents", "readwrite");
      let objectStore = transaction.objectStore("HomeEvents");
      objectStore.clear();
      events.forEach(function (item, index) {
        item.id = index;
        objectStore.put(item);
      });
      setOfflineMessage("Events data available in offline mode");
    };

    //Storing ActiveUsers data in IndexDB
    axios
      .get(`${configData.VENUE_URL}Member/GetUserDataForOffline`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data.payload;
      })
      .then((data) => {
        let request = window.indexedDB.open("MyDatabase", 1);
        request.onsuccess = (event) => {
          let db = event.target.result;
          let transaction = db.transaction(`ActiveUsers`, "readwrite");
          let objectStore = transaction.objectStore(`ActiveUsers`);
          objectStore.clear();
          objectStore.put({ activeUsers: data, id: 8 });
        };
      })
      .catch(() => {
        //setDataDownloadedStatus(false)
      });

    //Storing event Counts
    events?.forEach((event) => {
      let eventID = event?.eventID;
      axios
        .post(
          `${configData.VENUE_URL}Member/GetBookedCountByEventId?eventid=${eventID}`,
          {},
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          console.log(data);
          let request = window.indexedDB.open("MyDatabase", 1);
          request.onsuccess = (event) => {
            let db = event.target.result;
            let transaction = db.transaction(
              `BookedCount${eventID}`,
              "readwrite"
            );
            let objectStore = transaction.objectStore(`BookedCount${eventID}`);
            objectStore.clear();
            objectStore.put({ eventCount: data?.data?.payload, id: 8 });
          };
        });

      axios
        .post(
          `${configData.VENUE_URL}Member/GetBookingAmountByEventId?eventid=${eventID}`,
          {},
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          console.log(data);
          let request = window.indexedDB.open("MyDatabase", 1);
          request.onsuccess = (event) => {
            let db = event.target.result;
            let transaction = db.transaction(
              `BookingAmount${eventID}`,
              "readwrite"
            );
            let objectStore = transaction.objectStore(
              `BookingAmount${eventID}`
            );
            objectStore.clear();
            objectStore.put({ Booking: data?.data?.payload, id: 9 });
          };
        });
    });
    //Storing expensetypes data in IndexDB
    axios
      .get(`${configData.VENUE_URL}Events/expensetypes`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data.payload;
      })
      .then((data) => {
        let request = window.indexedDB.open("MyDatabase", 1);
        request.onsuccess = (event) => {
          let db = event.target.result;
          let transaction = db.transaction(`Expensetypes`, "readwrite");
          let objectStore = transaction.objectStore(`Expensetypes`);
          objectStore.clear();
          objectStore.put({ expensetypes: data, id: 9 });
        };
      })
      .catch(() => {
        //setDataDownloadedStatus(false)
      });

    // Storing EntryType data in IndexedDB
    events?.forEach((event) => {
      axios
        .get(
          `${configData.VENUE_URL}Events/
        ${event?.eventID}/getentrytypes`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.payload;
        })
        .then((data) => {
          let eventID = event?.eventID;
          let request = window.indexedDB.open("MyDatabase", 1);
          request.onsuccess = (event) => {
            let db = event.target.result;
            let transaction = db.transaction(
              `EntryType${eventID}`,
              "readwrite"
            );
            let objectStore = transaction.objectStore(`EntryType${eventID}`);
            objectStore.clear();
            objectStore.put(data);
          };
        })
        .catch(() => {
          //setDataDownloadedStatus(false)
        });
    });

    // Storing the EventInfo data in indexedDB
    events?.forEach((event) => {
      axios
        .get(`${configData.MEMBER_URL}events/${event?.eventID}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          let eventId = data.result.eventID;
          let eventData = data.result;
          let request = window.indexedDB.open("MyDatabase", 1);
          request.onsuccess = (event) => {
            let db = event.target.result;
            let transaction = db.transaction(
              `EventInfo${eventId}`,
              "readwrite"
            );
            let objectStore = transaction.objectStore(`EventInfo${eventId}`);
            objectStore.clear();
            objectStore.put(eventData);
          };
        })
        .catch(() => {});
    });

    // Storing the Event Expenditures data in indexedDB
    events?.forEach((event) => {
      axios
        .get(`${configData.VENUE_URL}Events/${event?.eventID}/getexpenditure`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          let eventId = event?.eventID;
          let eventData = data.payload;
          let request = window.indexedDB.open("MyDatabase", 1);
          request.onsuccess = (event) => {
            let db = event.target.result;
            let transaction = db.transaction(
              `Expenditure${eventId}`,
              "readwrite"
            );
            let objectStore = transaction.objectStore(`Expenditure${eventId}`);
            objectStore.clear();
            objectStore.put({ data: eventData, id: 8 });
          };
        })
        .catch(() => {
          //setDataDownloadedStatus(false)
        });
    });

    // Storing the Booking and Attendance data in indexedDB
    events?.forEach((event) => {
      axios
        .get(
          `${configData.VENUE_URL}Attendance/${event?.eventID}/getattendance`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data.payload;
          //setDataDownloadedStatus(true);
        })
        .then((data) => {
          let bookingDetails = data.attendanceHistoryCount;
          let attendanceDetails = data.attendanceHistory;
          let eventId = event.eventID;
          let request = window.indexedDB.open("MyDatabase", 1);
          request.onsuccess = (event) => {
            let db = event.target.result;
            let transaction = db.transaction(
              `EventBookings${eventId}`,
              "readwrite"
            );
            let objectStore = transaction.objectStore(
              `EventBookings${eventId}`
            );
            objectStore.clear();
            objectStore.put(bookingDetails);

            let transaction1 = db.transaction(
              `EventAttendance${eventId}`,
              "readwrite"
            );
            let objectStore1 = transaction1.objectStore(
              `EventAttendance${eventId}`
            );
            objectStore1.clear();
            attendanceDetails.forEach(function (item, index) {
              item.id = index;
              objectStore1.put(item);
            });
          };
        })
        .catch(() => {});
    });
  }, []);

  return <div></div>;
};

export default StoringDataInDb;
