import * as React from "react";
import Box from "@mui/material/Box";
import "../../App.css";
import { Button, FormControl, Typography } from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./../../App.css";
import { useTranslation } from "react-i18next";
import NewMemberListing from "../Event/NewMembersListing";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import configData from "./../../config.js";
import moment from "moment";
import FindMemberAttendance from "../Event/FindMemberAttendance";
import MemberDetailModal from "../modal/MemberDetailModal.js";
import { useLocation, useNavigate } from "react-router-dom";
import RecordAttendanceList from "../Event/RecordAttendanceList";
import FindMemberAttendanceOffline from "../Event/FindMemberAttendanceOffline";
import UpdateAttendance from "../Event/UpdateAttendance";
import UpdateAttendanceOffline from "../Event/UpdateAttendanceOffline";
import MemberDetailModalOffline from "../modal/MemberDetailModalOffline";
import { useLoader } from "../../components/SpinnerContext";

const SearchMember = ({
  handleModal,
  toggle,
  mode,
  search,
  setEventID,
  setIsAttendanceUpdated,
  eventID,
  setAttendanceData,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openDrawer] = React.useState(false);
  const [eventList, setEventList] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const token = localStorage.getItem("token");
  const [filterVenue] = useState("");
  const [filterType] = useState("");
  const [filterStatus] = useState("");
  const [initialEventList, setInitialEventList] = useState([]);
  const [dateRange] = useState([null, null]);
  const [startDate] = dateRange;
  const [startTime] = useState(null);
  const [endTime] = useState(null);
  const territoryNumber = localStorage.getItem("territoryNumber");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [guid] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo] = useState();
  const [dob] = useState();
  const [gender] = useState("");
  const [address] = useState("");
  const [postcode, setPostcode] = useState();
  const [companyName] = useState("");
  const [membercard] = useState();
  const [membersList, setMembersList] = useState();
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState();
  const [deletedUserId, setDeletedUserId] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [findMemberID, setFindMemberID] = useState("");
  const [resetAttendanceFields, setResetAttendanceFields] = useState(false);
  const location = useLocation();
  const [updateList, setUpdateList] = useState(true);
  const eID = location.state ? location.state.eventID : "";
  const [activeUsers, setActiveUsers] = useState([]);
  const [newAddedUsers, setNewAddedUsers] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [updateAttendance, setUpdateAttendance] = useState(false);
  const [attendance, setAttendance] = useState();
  const [entryTypeData, setEntryTypeData] = useState([]);
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [updatedMember, setUpdatedMember] = useState();
  const [createdAttendance, setCreatedAttendance] = useState([]);
  const { setIsLoading } = useLoader();
  const [defaulSort,setDefaultSort] = useState(true)
  const firstNameBtnRef = React.useRef(null)
  const lastNameBtnRef = React.useRef(null)
  const emailBtnRef = React.useRef(null)
  const postcodeBtnRef = React.useRef(null)
  const searchBtnRef = React.useRef(null)
  
  useEffect(() => {
    if (!eventID) {
      if (!eID) {
        navigate("/home");
      } else {
        setEventID(eID);
      }
    }
  }, [eID]);

  useEffect(() => {
    if (mode === "offline") {
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction(`ActiveUsers`, "readonly");
        let objectStore = transaction.objectStore(`ActiveUsers`);
        let request = objectStore.getAll();
        request.onsuccess = (event) => {
          let users = event.target.result[0];
          setActiveUsers(users.activeUsers);
        };
      };
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "offline") {
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction(`AddMember`, "readonly");
        let objectStore = transaction.objectStore(`AddMember`);
        let request = objectStore.getAll();
        request.onsuccess = (event) => {
          let users = event.target.result[0];
          if (users && users.createMemberRequests) {
            setNewAddedUsers(users.createMemberRequests);
          }
        };
      };
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "offline" && updatedMember) {
      setActiveUsers((prevArray) =>
        prevArray.map((obj) =>
          obj.memberID === updatedMember.MemberID
            ? { ...obj, ...updatedMember }
            : obj
        )
      );
      setAttendanceList((prevArray) =>
        prevArray.map((obj) =>
          obj.memberCardNumber === updatedMember.memberCardNumber
            ? { ...obj, ...updatedMember }
            : obj
        )
      );
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction1 = db.transaction(
          `EventAttendance${eventID}`,
          "readwrite"
        );
        let objectStore1 = transaction1.objectStore(
          `EventAttendance${eventID}`
        );
        objectStore1.clear();
        attendanceList.forEach(function (item, index) {
          item.id = index;
          objectStore1.put(item);
        });
      };
      setUpdatedMember();
    }
  }, [updatedMember]);

  useEffect(() => {
    setAttendanceData(attendanceList);
  }, [attendanceList]);

  useEffect(() => {
    if (mode !== "offline") {
      axios
        .get(`${configData.VENUE_URL}Events/geteventlist/${territoryNumber}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.payload === null) {
            setEventList([]);
          } else {
            setEventList(res?.data?.payload);
            setInitialEventList(res?.data?.payload);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [openDrawer]);

  useEffect(() => {
    if (mode === "offline" && eventID && updateList) {
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;

        //Fetching Attendance Data
        let transaction2 = db.transaction(
          `EventAttendance${eventID}`,
          "readonly"
        );
        let objectStore2 = transaction2.objectStore(
          `EventAttendance${eventID}`
        );
        let request2 = objectStore2.getAll();
        request2.onsuccess = (event) => {
          setAttendanceList(event.target.result);
          setUpdateList(false);
        };
      };
    }
  }, [mode, eventID, updateList]);

  const fetchAttendanceData = async () => {
    setIsLoading(true)
    if (mode !== "offline" && updateList && eventID) {
    try {
      const res = await fetch(
        `${configData.VENUE_URL}Attendance/${eventID}/getattendance?sortBy=entryDateTime&sortDir=desc`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
    if (data?.payload?.attendanceHistory.length < 1) {
      setAttendanceList([]);
    } else {
      setAttendanceList(data?.payload?.attendanceHistory);
      setIsAttendanceUpdated(true);
    }
    setUpdateList(false);
    } catch (error) {
      console.log(error);
    }
  };
  setIsLoading(false)
}

  useEffect(() => {
    fetchAttendanceData()
  }, [updateList, eventID, mode])
  
  const fetchOrderedAttendance = async() => {
    if (mode !== "offline" && eventID && order) {
      setIsLoading(true)
      try {
        const res = await fetch(
          `${configData.VENUE_URL}Attendance/${eventID}/getattendance?sortBy=${orderBy}&sortDir=${order}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
      if (data?.payload?.attendanceHistory.length < 1) {
        setAttendanceList([]);
      } else {
        setAttendanceList(data?.payload?.attendanceHistory);
        setIsAttendanceUpdated(true);
      }
      } catch (error) {
        console.log(error);
      }
        setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchOrderedAttendance()
  }, [order, orderBy, mode]);

  useEffect(() => {
    if (mode === "offline") {
      setAttendanceList((prevData) => {
        const newData = [...prevData];
        newData.sort((a, b) => {
          const aValue = a[orderBy];
          const bValue = b[orderBy];
          if (order === "asc") {
            if (typeof aValue === "string" && typeof bValue === "string") {
              return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
            }
            return aValue - bValue;
          } else {
            if (typeof aValue === "string" && typeof bValue === "string") {
              return bValue.toLowerCase().localeCompare(aValue.toLowerCase());
            }
            return bValue - aValue;
          }
        });
        return newData;
      });
    }
  }, [order, mode,updateList]);

  const deleteMemberAttendance = async() => {
      setIsLoading(true)
      try {
        await fetch(
          `${configData.BOOKING_URL}Members/DeleteAttendences/${deletedUserId.attendanceID}`,
          {
            method: "DELETE",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDeletedUserId();
        setUpdateList(true);
        setIsAttendanceUpdated(true);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false)
  }

  useEffect(() => {
    if (mode !== "offline" && deletedUserId) {
      deleteMemberAttendance()
    } else if (mode === "offline" && deletedUserId) {
      setAttendanceList((prevArray) =>
        prevArray.filter((obj) => {
          return obj.memberCardNumber !== deletedUserId?.memberCardNumber;
        })
      );
      if (createdAttendance.length > 0) {
        setCreatedAttendance((prevArray) =>
          prevArray.filter(
            (obj) => obj.memberCardNumber !== deletedUserId?.memberCardNumber
          )
        );
      }
      let request = window.indexedDB.open("MyDatabase", 1);
      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction1 = db.transaction(
          `EventAttendance${eventID}`,
          "readwrite"
        );
        let objectStore1 = transaction1.objectStore(
          `EventAttendance${eventID}`
        );
        objectStore1.clear();
        attendanceList.forEach(function (item, index) {
          if (item?.memberCardNumber !== deletedUserId?.memberCardNumber) {
            item.id = index;
            objectStore1.put(item);
          }
        });
        if (deletedUserId?.attendanceID) {
          let transaction3 = db.transaction(
            `DeleteAttendance${eventID}`,
            "readwrite"
          );
          let objectStore3 = transaction3.objectStore(
            `DeleteAttendance${eventID}`
          );
          objectStore3.put({ attendanceID: deletedUserId?.attendanceID });
        }

        //Removing from created attendance
        let transaction2 = db.transaction(
          `CreateAttendance${eventID}`,
          "readwrite"
        );
        let objectStore2 = transaction2.objectStore(
          `CreateAttendance${eventID}`
        );
        objectStore2.clear();
        createdAttendance.forEach(function (item) {
          if (item?.memberCardNumber !== deletedUserId?.memberCardNumber) {
            objectStore2.put(item);
          }
        });
        setDeletedUserId();
        setDefaultSort(true)
      };
    }
  }, [deletedUserId]);

  const isDateRangeValid = (date) => {
    return date[0] === null || date[1] === null;
  };

  const isDataRowWithinDateRange = (date) => {
    const eventDate = Date.parse(date?.split("T")[0]);
    let endDate;
    if (dateRange[1]) {
      let now_utc_endDate = new Date(
        dateRange[1].getTime() - dateRange[1].getTimezoneOffset() * 60000
      ).toISOString();
      endDate = Date.parse(now_utc_endDate);
    }
    if (eventDate >= startDate && eventDate <= endDate) {
      return true;
    } else return false;
  };

  const isTimeRangeValid = (startTime, endTime) => {
    return startTime === null || endTime === null;
  };

  const isTimeRowWithinTimeRange = (date) => {
    const eventTime = new Date();
    const eventTimeHours = date.split("T")[1];
    eventTime.setHours(eventTimeHours.split(":")[0]);
    eventTime.setMinutes(eventTimeHours.split(":")[1]);
    let now_utc_startTime;
    let newStartTime;
    let newEndTime;
    if (startTime) {
      now_utc_startTime = new Date(
        startTime.getTime() - startTime.getTimezoneOffset() * 60000
      ).toISOString();
      newStartTime = new Date();
      const startTimeHours = now_utc_startTime.split("T")[1];
      newStartTime.setHours(startTimeHours.split(":")[0]);
      newStartTime.setMinutes(startTimeHours.split(":")[1]);
    }
    if (endTime) {
      let now_utc_endTime = new Date(
        endTime.getTime() - endTime.getTimezoneOffset() * 60000
      ).toISOString();
      newEndTime = new Date();
      const endTimeHours = now_utc_endTime.split("T")[1];
      newEndTime.setHours(endTimeHours.split(":")[0]);
      newEndTime.setMinutes(endTimeHours.split(":")[1]);
    }
    if (eventTime >= newStartTime && eventTime <= newEndTime) {
      return true;
    } else return false;
  };

  useEffect(() => {
    let dataSet = initialEventList.filter((item) => {
      isTimeRowWithinTimeRange(item?.eventStart);
      if (
        (!filterVenue || filterVenue === item?.venueKnownAs) &&
        (!filterType || filterType === item?.eventTypeName) &&
        (!filterStatus || filterStatus === item?.eventStatusName) &&
        (isDateRangeValid(dateRange) ||
          isDataRowWithinDateRange(item?.eventStart)) &&
        (isTimeRangeValid(startTime, endTime) ||
          isTimeRowWithinTimeRange(item?.eventStart))
      ) {
        return true;
      }
      return false;
    });
    setEventList(dataSet);
  }, [filterVenue, filterType, filterStatus, dateRange, startTime, endTime]);

  useEffect(() => {
    if (!search) {
      setFilterFn({
        fn: (items) => {
          return items;
        },
      });
    } else {
      const delay = setTimeout(() => {
        if (search) {
          setFilterFn({
            fn: (items) => {
              if (search === "") return items;
              else
                return items.filter((item) =>
                  item.eventName.toLowerCase().includes(search)
                );
            },
          });
        }
      }, 2000);

      return () => clearTimeout(delay);
    }
  }, [search]);

  useEffect(() => {
    if (
      !firstName &&
      !lastName &&
      !guid &&
      !email &&
      (!mobileNo || mobileNo.length !== 10) &&
      !dob &&
      (!address || address.length < 5) &&
      (!postcode || postcode.length < 3) &&
      !companyName &&
      !membercard
    ) {
      setSearchDisabled(true);
    } else {
      setSearchDisabled(false);
    }
  }, [
    firstName,
    lastName,
    guid,
    email,
    mobileNo,
    dob,
    gender,
    address,
    postcode,
    companyName,
    membercard,
  ]);

  const handleSearch = async () => {
    if (
      !firstName &&
      !lastName &&
      (!email || email.length < 5) &&
      (!postcode || postcode.length < 3)
    ) {
      return;
    }
    if (mode === "online") {
      const rawData = {};
      if (firstName) {
        rawData["firstName"] = firstName;
      }
      if (lastName) {
        rawData["lastName"] = lastName;
      }
      if (email) {
        rawData["email"] = email;
      }
      if (postcode) {
        rawData["postalcode"] = postcode;
      }
      const res = await fetch(
        `${configData.VENUE_URL}Member/GetSearchMemberList`,
        {
          method: "POST",
          body: JSON.stringify(rawData),
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const response = await res.json();
      if (response?.payload) {
        setMembersList(response?.payload ?? []);
      } else if (response?.errorMessage) {
        setMembersList([]);
      }
    } else {
      let data = [...activeUsers];
      if (firstName) {
        data = data.filter(
          (f) => f.firstName?.toLowerCase().startsWith(firstName?.toLowerCase())
        );
      }
      if (lastName) {
        data = data.filter(
          (f) => f.lastName?.toLowerCase().startsWith(lastName?.toLowerCase())
        );
      }
      if (postcode) {
        data = data.filter(
          (f) => f.addrPostCode.toLowerCase() === postcode.toLowerCase()
        );
      }
      if (email) {
        data = data.filter(
          (f) => f.emailId.toLowerCase() === email.toLowerCase()
        );
      }
      if (data.length > 0) {
        data.sort((a, b) => {
          const lastNameComparison = a.lastName.localeCompare(b.lastName);
          if (lastNameComparison !== 0) {
              return lastNameComparison;
          }
          return a.firstName.localeCompare(b.firstName);
      });
        setMembersList(data);
      } else {
        setMembersList([]);
      }
    }
  };

  const resetField = () => {
    //DEV
    setFirstName("");
    setLastName("");
    setEmail("");
    setPostcode("");
    setMembersList([]);
    setResetAttendanceFields(true);
  };

  return (
    <div style={{ marginTop: "-2rem" }}>
      <Box style={{ display: "flex" }}>
        <Box style={{ flex: "1", minWidth: "25%" }}>
          {mode === "online" ? (
            <FindMemberAttendance
              eventID={eventID}
              selectedUserId={selectedUserId}
              resetAttendanceFields={resetAttendanceFields}
              setResetAttendanceFields={setResetAttendanceFields}
              setUpdateList={setUpdateList}
              setEntryTypeData={setEntryTypeData}
            />
          ) : (
            <FindMemberAttendanceOffline
              setIsLoading={setIsLoading}
              eventID={eventID}
              activeUsers={activeUsers}
              newAddedUsers={newAddedUsers}
              selectedUserId={selectedUserId}
              resetAttendanceFields={resetAttendanceFields}
              setResetAttendanceFields={setResetAttendanceFields}
              setUpdateList={setUpdateList}
              attendanceList={attendanceList}
              setDefaultSort={setDefaultSort}
              t={t}
              mode={mode}
            />
          )}
        </Box>
        <Box style={{ flex: "3", maxWidth: "75%" }}>
          <Box style={{ marginTop: "47px" }}>
            <div style={{ marginTop: "1rem" }}>
              <Accordion
                expanded={!isCollapsed}
                style={{ background: "rgba(0,0,0,0.03)", marginBottom: "20px" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ display: "flex" }}>
                    <h5>{t("FIND MEMBER")}</h5>
                    <span
                      style={{
                        height: "fit-content",
                        marginLeft: "10px",
                        marginTop: "auto",
                        marginBottom: "9px",
                        fontSize: "12px",
                      }}
                    >
                      (select atleast one filter)
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{ gap: "1rem" }}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <FormControl
                      sx={{ minWidth: "14%", mb: "1rem" }}
                      size="small"
                    >
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setFirstName(e.target.value)}
                          ref={firstNameBtnRef}
                          onKeyDown={(event) => {
                    const { key } = event;
                      if (key === "Enter") {
                        firstNameBtnRef.current.blur()
                        searchBtnRef.current.click()
                      }
                    }}
                          value={firstName}
                          placeholder="First Name"
                        />
                        <label htmlFor="floatingInput">{t("First Name")}</label>
                      </div>
                    </FormControl>
                    <FormControl
                      sx={{ minWidth: "14%", mb: "1rem" }}
                      size="small"
                    >
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                          ref={lastNameBtnRef}
                          onKeyDown={(event) => {
                    const { key } = event;
                      if (key === "Enter") {
                        lastNameBtnRef.current.blur()
                      searchBtnRef.current.click()
                      }
                    }}
                          placeholder="Last Name"
                        />
                        <label htmlFor="floatingInput">{t("Last Name")}</label>
                      </div>
                    </FormControl>
                    <FormControl
                      sx={{ minWidth: "14%", mb: "1rem" }}
                      size="small"
                    >
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          ref={emailBtnRef}
                          onKeyDown={(event) => {
                    const { key } = event;
                      if (key === "Enter") {
                        emailBtnRef.current.blur()
                      searchBtnRef.current.click()
                      }
                    }}
                          value={email}
                          placeholder="Email Address"
                        />
                        <label htmlFor="floatingInput">
                          {t("Email Address")}
                        </label>
                      </div>
                    </FormControl>
                    <FormControl
                      sx={{ minWidth: "14%", mb: "1rem" }}
                      size="small"
                    >
                      <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setPostcode(e.target.value)}
                          value={postcode}
                          ref={postcodeBtnRef}
                          onKeyDown={(event) => {
                    const { key } = event;
                      if (key === "Enter") {
                        postcodeBtnRef.current.blur()
                      searchBtnRef.current.click()
                      }
                    }}
                          placeholder="Postal Code"
                        />
                        <label htmlFor="floatingInput">
                          {t("Postal Code")}
                        </label>
                      </div>
                    </FormControl>
                  </Box>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <Button
                      sx={{
                        textTransform: "none",
                        width: "14rem",
                        backgroundColor: "#F87B03",
                        ml: "auto",
                        color: "#fff",
                        p: "0.5rem 0.5rem",
                        "&:hover": {
                          backgroundColor: "#F87B03",
                        },
                      }}
                      ref={searchBtnRef}
                      onClick={handleSearch}
                      disabled={searchDisabled}
                    >
                      {t("Search")}
                    </Button>
                  </div>
                  <NewMemberListing
                    eventList={membersList}
                    filterFn={filterFn}
                    handleModal={handleModal}
                    t={t}
                    mode={mode}
                    toggle={toggle}
                    setSelectedUserId={setSelectedUserId}
                    setFindMemberID={setFindMemberID}
                    setShowModel={setShowModel}
                    showModel={showModel}
                    resetField={resetField}
                    setIsCollapsed={setIsCollapsed}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                defaultExpanded={true}
                style={{ background: "rgba(0,0,0,0.03)" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ display: "flex" }}>
                    <h5>{t("Recorded Attendance List")}</h5>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RecordAttendanceList
                    eventList={attendanceList}
                    filterFn={filterFn}
                    handleModal={handleModal}
                    t={t}
                    mode={mode}
                    toggle={toggle}
                    setDeletedUserId={setDeletedUserId}
                    setSelectedUserId={setSelectedUserId}
                    setFindMemberID={setFindMemberID}
                    setShowModel={setShowModel}
                    showModel={showModel}
                    resetField={resetField}
                    setUpdateAttendance={setUpdateAttendance}
                    setAttendance={setAttendance}
                    order={order}
                    orderBy={orderBy}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    defaulSort={defaulSort}
                    setDefaultSort={setDefaultSort}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </Box>
          {mode === "online" ? (
            <MemberDetailModal
              showModel={showModel}
              findMemberID={findMemberID}
              setFindMemberID={setFindMemberID}
              setShowModel={setShowModel}
              setAttendances={setAttendanceList}
              eventID={eventID}
              setIsAttendanceUpdated={setIsAttendanceUpdated}
            />
          ) : (
            <MemberDetailModalOffline
              activeUsers={activeUsers}
              showModel={showModel}
              findMemberID={findMemberID}
              setFindMemberID={setFindMemberID}
              setShowModel={setShowModel}
              setAttendances={setAttendanceList}
              eventID={eventID}
              setUpdatedMember={setUpdatedMember}
            />
          )}
          {mode === "online" ? (
            <UpdateAttendance
              updateAttendance={updateAttendance}
              entryTypeData={entryTypeData}
              setAttendances={setAttendanceList}
              setUpdateAttendance={setUpdateAttendance}
              territoryNumber={territoryNumber}
              attendance={attendance}
              eventID={eventID}
              setIsAttendanceUpdated={setIsAttendanceUpdated}
            />
          ) : (
            <UpdateAttendanceOffline
              mode={mode}
              updateAttendance={updateAttendance}
              entryTypeData={entryTypeData}
              setAttendances={setAttendanceList}
              setUpdateAttendance={setUpdateAttendance}
              territoryNumber={territoryNumber}
              attendance={attendance}
              eventID={eventID}
              attendanceList={attendanceList}
              setUpdateList={setUpdateList}
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default SearchMember;
