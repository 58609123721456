// LoaderContext.js
import React, { createContext, useState, useContext } from 'react';

// Create context
const LoaderContext = createContext();

// Create a provider component
export const LoaderProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
            {children}
        </LoaderContext.Provider>
    );
};

// Create a custom hook to use the LoaderContext
export const useLoader = () => useContext(LoaderContext);