import * as React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { Button, LinearProgress, TextField, Tooltip } from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import configData from "../config";
import PasswordValidation from "./PasswordValidation";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

const drawerWidth = 240;

function NewChangePassword(props) {
  React.useEffect(() => {
    loadCaptchaEnginge(8);
  }, []);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [enable, setEnable] = React.useState(false);

  const doSubmit = () => {
    let user_captcha = document.getElementById("user_captcha_input").value;

    if (validateCaptcha(user_captcha) === true) {
      alert("Captcha Matched");
      loadCaptchaEnginge(6);
      document.getElementById("user_captcha_input").value = "";
    } else {
      alert("Captcha does not match");
      document.getElementById("user_captcha_input").value = "";
    }
  };

  const ChangePasswordHandler = () => {
    if (newPassword !== confirmPassword) {
      alert("Password does not match");
    }

    doSubmit();
    const token =
      "eyJhbGciOiJSUzI1NiIsImtpZCI6IlJPOFExQ296aFROdEE3SXFjMVdLcWciLCJ0eXAiOiJhdCtqd3QifQ.eyJuYmYiOjE2Njg2ODg4NTAsImV4cCI6MTY2ODY5MjQ1MCwiaXNzIjoiaHR0cDovL2Nlcm9jLWRldmF1dGgua2VsbHRvbnRlY2gubmV0IiwiYXVkIjoiYm9va2luZ3phcHAiLCJjbGllbnRfaWQiOiJCb29raW5nekFwcFZlbnVlQXBwIiwic3ViIjoiMjZiZWVkN2YtNTMxNS00OWE5LTlhOGItMDMxNGQwMzFmOWYxIiwiYXV0aF90aW1lIjoxNjY4Njg4ODUwLCJpZHAiOiJsb2NhbCIsInNjb3BlIjpbImJvb2tpbmd6YXBwLm1lbWJlci5yZWFkIiwiYm9va2luZ3phcHAubWVtYmVyLndyaXRlIiwiYm9va2luZ3phcHAudmVudWUucmVhZCIsImJvb2tpbmd6YXBwLnZlbnVlLndyaXRlIl0sImFtciI6WyJwYXNzd29yZCJdfQ.lWZSbnWJq9RiCQ7bSCgvVJy6gc-N9yRjrkuo3TpB5RYxum8yl6BhOuIszcpmooTIWK55oPgmL2oSNgkI27A5TtCrv1fmkt7oiuiZYvwsRhIjczBcnUF6xD_1tU46aTn1KebQ8snV5tjPs7kbY_enwKbmo9GXptUdqZpd6mQi0ab9lBi9_ZHGkyOdIB5c6kViRcHlZQY7KEWnfltvG22v9h75tsyIaUf0aQ_bZXW6rd562LGg_jNntPPjGDWyMhzQhc4ZJAogHvdINliVn2FF-tsH6IuLzLOOVw_8okz4IuPCjmsvgcx4hqlVuBxm7TK-3-c5pR3C19-8aTAGSW8IKQ";

    const headers = {
      Authorization: `bearer ${token}`,
    };
    let reqObj = {
      MemberID: "26BEED7F-5315-49A9-9A8B-0314D031F9F1",
      AddrDigital: "abhishek.negi@kellton.com",
      CurrentPassword: `${currentPassword}`,
      NewPassword: `${newPassword}`,
    };
    axios
      .put(`${configData.VENUE_URL}User/pwd`, reqObj, {
        headers,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const drawer = <div></div>;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box
          sx={{
            width: "50rem",
            height: "30rem",
            display: "flex",
            flexDirection: "column",
            marginTop: "5rem",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography paragraph sx={{ marginTop: "1rem", paddingLeft: "4rem" }}>
            Just one step to go and your profile's complete
          </Typography>
          <div className="flex">
            <LinearProgress
              sx={{ mx: "4rem", width: "80%" }}
              variant="determinate"
              value={65}
              color="success"
            />
            <ThumbUpOutlinedIcon
              sx={{ marginRight: "5rem", marginTop: 0 }}
              size="small"
              color="success"
            />
          </div>
          <div className="ch-ps-st-flex">
            <img
              className=".ch-ps-img-flex"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVA_HrQLjkHiJ2Ag5RGuwbFeDKRLfldnDasw&usqp=CAU"
              alt="default profile photo"
            />
          </div>
          <div className="ch-ps-div-flex">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                sx={{ width: "20rem" }}
                type="password"
                label="Current Password"
                size="small"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <br />
              <Tooltip
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      border: "solid skyblue 1px",
                      backgroundColor: "white",
                    },
                  },
                }}
                arrow
                open={enable}
                title={<PasswordValidation newPassword={newPassword} />}
                placement="right"
              >
                <TextField
                  sx={{ width: "20rem" }}
                  type="password"
                  label="New Password"
                  size="small"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onFocus={() => setEnable(true)}
                  onBlur={() => setEnable(false)}
                />
              </Tooltip>
              <br />
              <TextField
                sx={{ width: "20rem" }}
                type="password"
                label="Confirm Password"
                size="small"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.7rem",
              }}
            >
              <div>
                <Typography paragraph>
                  Enter the below Security Code <br />
                  <LoadCanvasTemplate reloadColor="red">
                    Can't read
                  </LoadCanvasTemplate>
                </Typography>
              </div>
              <TextField
                sx={{ width: "20rem" }}
                label="Security Code"
                size="small"
                type="text"
                id="user_captcha_input"
                name="user_captcha_input"
              />
            </div>
          </div>
          <Button
            sx={{
              backgroundColor: "#F87B03",
              width: "20rem",
              margin: "auto",
            }}
            onClick={ChangePasswordHandler}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NewChangePassword;
